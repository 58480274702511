import { useMemo } from 'react'

import Cookies from 'js-cookie'
import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'
import { useGetQueryParam } from 'src/hooks/useGetQueryParam'

import useCobrandingNewPartners from '../features/useCobrandingNewPartners'

export enum CobrandingNewPartners {
  BabyCenter = 'BabyCenter',
  BusinessInsider = 'BusinessInsider',
  Chime = 'Chime',
  FundCom = 'FundCom',
  InsuranceRanked = 'InsuranceRanked',
  JoyWallet = 'JoyWallet',
  MoneyTalksNews = 'MoneyTalksNews',
  MoneyWise = 'MoneyWise',
  Moonshot = 'Moonshot',
  PerkSpot = 'PerkSpot',
  InsuranceIO = 'InsuranceIO',
  Spotower = 'Spotower',
  VoicedMedia = 'VoicedMedia',
  WhatToExpect = 'WhatToExpect',
  GoodRX = 'GoodRX',
  MarketWatch = 'MarketWatch',
  NeighborhoodAlerts = 'NeighborhoodAlerts',
  CreditKarma = 'CreditKarma',
  BestMoney = 'BestMoney',
  Sofi = 'Sofi',
  ForbesUsaToday = 'ForbesUsaToday',
  Forbes = 'Forbes',
}

// UTM Term to Partner Mapping
const UTM_TERM_TO_PARTNER: Record<string, CobrandingNewPartners> = {
  '1430864': CobrandingNewPartners.BabyCenter,
  '4722637': CobrandingNewPartners.BusinessInsider,
  '469312': CobrandingNewPartners.Chime,
  '3126039': CobrandingNewPartners.FundCom,
  '1421671': CobrandingNewPartners.InsuranceRanked,
  '2089525': CobrandingNewPartners.JoyWallet,
  '25978': CobrandingNewPartners.MoneyTalksNews,
  '2259450': CobrandingNewPartners.MoneyWise,
  '1928938': CobrandingNewPartners.Moonshot,
  '55869': CobrandingNewPartners.PerkSpot,
  '5741215': CobrandingNewPartners.InsuranceIO,
  '2213888': CobrandingNewPartners.Spotower,
  '369169': CobrandingNewPartners.VoicedMedia,
  '1442498': CobrandingNewPartners.WhatToExpect,
  '58785': CobrandingNewPartners.GoodRX,
}

// UTM Term + UTM Source for Duplicates
const UTM_TERM_WITH_MULTIPLE_PARTNERS: Record<
  string,
  Record<string, CobrandingNewPartners>
> = {
  '2421403': {
    '1104720': CobrandingNewPartners.MarketWatch, // MarketWatch utm_source
    '2548682': CobrandingNewPartners.NeighborhoodAlerts, // NeighborhoodAlerts utm_source
  },
}

const useGetCobrandingNewPartner = (): CobrandingNewPartners | null => {
  const { isTreatment1 } = useCobrandingNewPartners()
  const utmTerm = useGetQueryParam('utm_term')
  const utmSource = useGetQueryParam('utm_source')

  return useMemo<CobrandingNewPartners | null>(() => {
    if (!isTreatment1 || !utmTerm) return null

    // First, check direct match for utm_term
    if (UTM_TERM_TO_PARTNER[utmTerm]) {
      Cookies.set(
        COBRANDING_NEW_PARTNERS.cookieName,
        UTM_TERM_TO_PARTNER[utmTerm],
        { expires: 365 }
      )
      return UTM_TERM_TO_PARTNER[utmTerm]
    }

    // Check if utm_term exists in the multiple partners list and needs utm_source
    const partnerKey =
      UTM_TERM_WITH_MULTIPLE_PARTNERS[utmTerm]?.[utmSource ?? ''] ?? null

    if (partnerKey) {
      Cookies.set(COBRANDING_NEW_PARTNERS.cookieName, partnerKey, {
        expires: 365,
      })
    }

    return partnerKey
  }, [isTreatment1, utmTerm, utmSource])
}

export default useGetCobrandingNewPartner
