interface Props {
  light?: boolean
}

const EthosLogo = ({ light }: Props) => {
  const fillColor = light ? '#FFFFFF' : '#022925'

  return (
    <svg
      width="81"
      height="19"
      viewBox="0 0 81 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_47368_5509)">
        <path
          d="M15.6451 1.82072V4.19146H20.3875V16.0466H22.7583V4.19146H27.5002V1.82072H15.6451ZM0.660645 1.82072V16.0466H11.1592V13.6754H3.03139V10.119H10.0934V7.7483H3.03139V4.19146H11.1592V1.82072H0.660645ZM41.7263 1.82072V7.7483H34.6136V1.82072H32.2424V16.0466H34.6136V10.119H41.7263V16.0466H44.097V1.82072H41.7263ZM62.2406 3.16212C61.7596 2.68114 61.1886 2.29961 60.5602 2.0393C59.9318 1.779 59.2582 1.64502 58.578 1.64502C57.8978 1.64502 57.2243 1.779 56.5959 2.0393C55.9675 2.29961 55.3965 2.68114 54.9155 3.16212C54.6232 3.45487 54.0144 4.13926 53.8064 4.47633C54.7954 3.86585 55.9613 3.60622 57.1159 3.73928C58.2706 3.87234 59.3468 4.39034 60.1711 5.20978C60.9953 6.02923 61.5197 7.10237 61.6595 8.25622C61.7994 9.41007 61.5466 10.5774 60.942 11.57L61.0219 11.4902C61.3687 11.2797 61.941 10.7868 62.2406 10.4872C62.7216 10.0062 63.1031 9.43525 63.3634 8.80683C63.6237 8.17841 63.7577 7.50487 63.7577 6.82467C63.7577 6.14447 63.6237 5.47093 63.3634 4.84251C63.1031 4.21409 62.7216 3.64309 62.2406 3.16212Z"
          fill={fillColor}
        />
        <path
          d="M53.4606 11.9419C51.4254 9.90682 50.1789 6.16341 52.4756 2.20617L51.402 3.27975C49.9805 4.7014 49.1818 6.62956 49.1819 8.64003C49.1819 10.6505 49.9806 12.5786 51.4023 14.0002C52.8239 15.4218 54.7521 16.2204 56.7626 16.2204C58.773 16.2203 60.7012 15.4216 62.1227 14L63.1963 12.9264C59.4276 15.1174 55.6774 14.1588 53.4606 11.9419ZM73.9038 16.2589C77.3476 16.2589 79.5727 14.5526 79.5727 11.9115C79.5727 9.43538 77.8323 8.53976 75.0236 7.89873L73.3032 7.52122C71.7542 7.17879 70.9144 6.78082 70.9144 5.65853C70.9144 4.51432 71.9807 3.77506 73.631 3.77506C75.1708 3.77506 76.5259 4.34692 77.3608 5.34695L79.1475 3.9108C78.164 2.84728 76.4289 1.60516 73.6939 1.60516C70.6037 1.60516 68.3605 3.36264 68.3605 5.78453C68.3605 8.54836 70.6231 9.39203 72.5945 9.81825L74.5039 10.2381C76.0134 10.566 77.0188 11.0156 77.0188 12.1422C77.0188 13.3532 75.9292 14.0468 74.03 14.0468C71.9583 14.0468 70.3923 13.0292 69.7361 12.0034L67.7755 13.415C68.7853 14.7989 70.9125 16.2588 73.9038 16.2588"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_47368_5509">
          <rect
            width="80"
            height="17.9592"
            fill="white"
            transform="translate(0.0991211 0.200684)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EthosLogo
