import { useRouter } from 'next/router'

import Cookies from 'js-cookie'
import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'

import { useGetQueryParam } from '../useGetQueryParam'
import { CobrandingNewPartners } from './useGetCobrandingNewPartners'

const audiencePaths = ['/life/quick-online-quote/']

const cookie = {
  name: 'FORBES-USA-TODAY-NAVBAR',
  value: 'on',
  expires: 365,
}

export const useForbesUsaTodayNavbar = (newPartnersTreatment = false) => {
  const { asPath } = useRouter()
  const queryParams = useGetQueryParam('utm_term')

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath = audiencePaths.includes(pathnameWithoutQuery)

  const isForbesUsaTodayTerm = queryParams === '4012387'

  const isEnabled = isValidAudiencePath && isForbesUsaTodayTerm

  if (isEnabled) {
    if (newPartnersTreatment) {
      Cookies.set(
        COBRANDING_NEW_PARTNERS.cookieName,
        CobrandingNewPartners.ForbesUsaToday,
        { expires: 365 }
      )
    }
    // cookie used by PreInterview
    Cookies.set(cookie.name, cookie.value, {
      expires: cookie.expires,
    })
  }

  return isEnabled
}
