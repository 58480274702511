import React from 'react'

import classNames from 'classnames'
import {
  Body2,
  Button,
  COLORS,
  CloudinaryImage,
  Spacer,
  TitleLarge2,
  TitleXLarge2,
} from 'ethos-design-system'

import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { Subheading } from '../common/Subheading'
import { SingleCTAInterface } from '../interface'

const CurvedOverlay = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
}): JSX.Element => {
  const headingElement = moduleData.enableH1 ? 'h1' : 'div'
  const heading = moduleData.heading
  const subHeading = moduleData.subHeading

  const imageContainerClasses = classNames(
    'relative z-0 hidden w-full overflow-hidden md:block',
    styles.imageContainer
  )
  const backgroundFillerClasses = classNames(
    'z-1 hidden lg:absolute lg:top-0 lg:left-0 lg:block lg:h-full',
    styles.backgroundFiller
  )
  const overlayClasses = classNames(
    'relative z-1 md:absolute md:top-0 md:h-full',
    styles.curvedOverlay
  )
  const ctaClasses = classNames('text-center', styles.ctaContainer)

  return (
    <>
      <div className="relative w-full">
        <div className={imageContainerClasses}>
          {moduleData.groupImage && moduleData.imageAlt && (
            <PriorityImage
              publicId={moduleData.groupImage}
              alt={moduleData.imageAlt}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[10, 521, 521, 695]}
              width={[10, 1440, 1440, 1920]}
            />
          )}
        </div>
        <div className="relative w-full md:hidden">
          {moduleData.mobileImage && moduleData.imageAlt && (
            <PriorityImage
              publicId={moduleData.mobileImage}
              alt={moduleData.imageAlt}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[270, 340, 10, 10]}
              width={[600, 750, 10, 10]}
              fetchpriority="high"
            />
          )}
        </div>
        <div className={backgroundFillerClasses} />
        <div className={styles.contentContainer}>
          <div className={overlayClasses}>
            <div className="p-4">
              <div className="lg:hidden">
                <TitleLarge2.Serif.Book500
                  color={COLORS.WHITE}
                  element={headingElement}
                >
                  {heading}
                </TitleLarge2.Serif.Book500>
              </div>
              <div className="hidden lg:block lg:max-w-md lg:pt-4 xl:pt-16">
                <TitleXLarge2.Serif.Book500
                  color={COLORS.WHITE}
                  element={headingElement}
                >
                  {heading}
                </TitleXLarge2.Serif.Book500>
              </div>
              {moduleData.displaySubHeading && (
                <Subheading
                  displaySubHeading={moduleData.displaySubHeading}
                  color={COLORS.WHITE}
                >
                  {subHeading}
                </Subheading>
              )}
              <div className={ctaClasses}>
                <Button.Medium.Lime fullWidth onClick={(): void => ctaClick()}>
                  {moduleData.ctaLabel}
                </Button.Medium.Lime>
                {moduleData.subText && (
                  <div className="mx-auto">
                    <Spacer.H16 />
                    <Body2.Regular400 color={COLORS.WHITE}>
                      {moduleData.subText}
                    </Body2.Regular400>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurvedOverlay
