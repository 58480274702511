import { useRouter } from 'next/router'

import { SOFI_DEMO_LANDING_PAGE_URL, SOFI_LANDING_PAGE_URL } from '@/constants'
import Cookies from 'js-cookie'
import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'

import { CobrandingNewPartners } from './useGetCobrandingNewPartners'

export const useIsSofi = (newPartnersTreatment = false) => {
  const { asPath } = useRouter()

  const pathWithoutQuery = asPath.split('?')[0]
  const isSofiPage =
    pathWithoutQuery === SOFI_DEMO_LANDING_PAGE_URL ||
    pathWithoutQuery === SOFI_LANDING_PAGE_URL

  if (isSofiPage) {
    if (newPartnersTreatment) {
      Cookies.set(
        COBRANDING_NEW_PARTNERS.cookieName,
        CobrandingNewPartners.Sofi,
        {
          expires: 365,
        }
      )
    }
    // cookie used by PreInterview
    Cookies.set('SOFI-HEADER-FEATURE', 'on', {
      expires: 365,
    })
  }

  return isSofiPage
}
