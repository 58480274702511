import React, { useEffect } from 'react'

import Image from 'next/image'

import { useModuleContext } from '@/contexts/ModuleContext'
import { BestMoneyLogo, ForbesLogo, ForbesUsaTodayLogo, Plus } from '@/icons'
import consumerLogoBlack from '@/img/ethos-logo-black.svg'
import consumerLogo from '@/img/ethos-logo-forest.svg'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import { CloudinaryImage } from 'ethos-design-system'

import { SingleCTAInterface } from '@/components/SingleCTA/interface'

import useCheckCobrandingNewPartnersLogos from '@/hooks/cobranded/useCheckCobrandingNewPartnersLogos'
import { useCobrandedUtmTerms } from '@/hooks/cobranded/useCobrandedTerms'
import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import { useBrandColorsLandingPageExperiment } from '@/hooks/features/useBrandColorsLandingPageExperiment'
import useCobrandingNewPartners from '@/hooks/features/useCobrandingNewPartners'
import { useCobrandingOnMobile } from '@/hooks/features/useCobrandingOnMobile'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import styles from './SingleCTADirect.module.scss'
import {
  TRUST_IMAGE_GROUPS,
  TRUST_IMAGE_GROUPS_7E7E7E,
  TRUST_IMAGE_GROUPS_FFFFFF,
} from './constants'

interface Props {
  ctaClick: (props: { flow?: string; label?: string }) => void
  moduleData: SingleCTAInterface
}

const SingleCTADirect = ({ ctaClick, moduleData }: Props) => {
  const { isLaptopAndUp } = useDetectScreenSize()

  const { isTreatment1 } = useCobrandingOnMobile()
  const { isTreatment1: newPartnersTreatment } = useCobrandingNewPartners()
  const { isForbesUtmTerm, isBestMoneyUtmTerm, isForbesUsaTodayTerm } =
    useCobrandedUtmTerms()

  let partnerLogoMobile: JSX.Element | null = null

  // --------- TODO: clean up after new partners treatment is over ---------
  const isForbesHeaderEnabled = useForbesHeaderNavbar(
    isTreatment1 || newPartnersTreatment
  )

  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar(
    isTreatment1 || newPartnersTreatment
  )
  const { isEnabled: isBestMoneyHeaderEnabled } = useBestMoneyHeader(
    isTreatment1 || newPartnersTreatment
  )

  if (isForbesUtmTerm || isForbesHeaderEnabled) {
    partnerLogoMobile = <ForbesLogo />
  } else if (isBestMoneyUtmTerm || isBestMoneyHeaderEnabled) {
    partnerLogoMobile = <BestMoneyLogo />
  } else if (isForbesUsaTodayTerm || isForbesUsaTodayHeaderEnabled) {
    partnerLogoMobile = <ForbesUsaTodayLogo />
  }
  // ------------------------------------------------------------------------

  partnerLogoMobile = useCheckCobrandingNewPartnersLogos(partnerLogoMobile)

  const {
    navBarControls: { hideNavBar },
  } = useModuleContext()

  useEffect(() => {
    hideNavBar(!isLaptopAndUp)
  }, [hideNavBar, isLaptopAndUp])

  const { headline } = useHeadlineRapidTest()
  const ctaLabel = moduleData.ctaLabel || 'Check my price'

  const { isVariant1, isVariant2, isVariant3 } =
    useBrandColorsLandingPageExperiment()

  let trustImageGroups = TRUST_IMAGE_GROUPS

  if (isVariant1 || isVariant2) {
    trustImageGroups = TRUST_IMAGE_GROUPS_FFFFFF
  } else if (isVariant3) {
    trustImageGroups = TRUST_IMAGE_GROUPS_7E7E7E
  }

  const shouldShowLogoWithPartner = isTreatment1 || newPartnersTreatment

  return (
    <section
      className={classNames(styles.wrapper, {
        [styles.variant1]: isVariant1,
        [styles.variant2]: isVariant2,
        [styles.variant3]: isVariant3,
      })}
    >
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div
            className={
              shouldShowLogoWithPartner ? styles.logoWithPartner : styles.logo
            }
          >
            {shouldShowLogoWithPartner && partnerLogoMobile ? (
              <>
                <Image
                  src={consumerLogoBlack}
                  alt="Ethos Logo"
                  width={87}
                  height={16}
                  fetchPriority="high"
                />
                <Plus />
                <div className={styles.mobileLogo}>{partnerLogoMobile}</div>
              </>
            ) : (
              <img src={consumerLogo.src} alt="Ethos Logo" />
            )}
          </div>

          <h1
            className={classNames(styles.header, {
              [styles.variant1]: isVariant1,
              [styles.variant2]: isVariant2,
              [styles.variant3]: isVariant3,
            })}
          >
            {headline ? (
              headline
            ) : (
              <>
                Instant <br /> life insurance
              </>
            )}
          </h1>
          <div className="mb-[42px] flex items-center gap-2">
            <p
              className={classNames(styles.subheader, {
                [styles.variant1]: isVariant1,
                [styles.variant2]: isVariant2,
                [styles.variant3]: isVariant3,
              })}
            >
              no medical exams
            </p>
            <div className="mb-1 h-[6px] w-[6px] rounded-full bg-[#82B0AB]"></div>
            <p
              className={classNames(styles.subheader, {
                [styles.variant1]: isVariant1,
                [styles.variant2]: isVariant2,
                [styles.variant3]: isVariant3,
              })}
            >
              no blood tests
            </p>
          </div>

          <Button
            className={classNames(styles.button, {
              [styles.variant1]: isVariant1,
              [styles.variant2]: isVariant2,
              [styles.variant3]: isVariant3,
            })}
            buttonTitle={ctaLabel}
            alignment="center"
            variant="salamander"
            type="submit"
            size="lg"
            onClick={() => ctaClick({ label: ctaLabel })}
          />
          <div className={styles.trustImages}>
            {trustImageGroups.map((trustImageGroup, index) => (
              <div className={styles.trustImage} key={`trustImage-${index}`}>
                <CloudinaryImage
                  publicId={trustImageGroup.groupImage}
                  alt={trustImageGroup.imageAlt}
                  className={styles.trustImage}
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={[55, 55, 55, 55]}
                  width={[90, 90, 90, 90]}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.bgContainer}>
          <div className={styles.bgImage}>
            <Image
              priority
              src="https://res.cloudinary.com/getethos/image/upload/v1665678125/family_stock_photo_2_plqkwh.png"
              alt="Family"
              width={500}
              height={560}
              quality={100}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SingleCTADirect
