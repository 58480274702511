import { SINGLE_CTA_EXPERIMENT } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { CONTROL, TREATMENT_2 } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const isTreatment2 = isEnabled && variation === TREATMENT_2
  const isTreatment = isEnabled && isTreatment2

  return {
    isLoading,
    isTreatment,
    isTreatment2,
  }
}
