import React, { useEffect } from 'react'

import Image from 'next/image'

import { useModuleContext } from '@/contexts/ModuleContext'
import { BestMoneyLogo, ForbesLogo, ForbesUsaTodayLogo, Plus } from '@/icons'
import consumerLogoBlack from '@/img/ethos-logo-black.svg'
import consumerLogo from '@/img/ethos-logo-forest.svg'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import { CloudinaryImage } from 'ethos-design-system'

import useCheckCobrandingNewPartnersLogos from '@/hooks/cobranded/useCheckCobrandingNewPartnersLogos'
import { useCobrandedUtmTerms } from '@/hooks/cobranded/useCobrandedTerms'
import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import useCobrandingNewPartners from '@/hooks/features/useCobrandingNewPartners'
import { useCobrandingOnMobile } from '@/hooks/features/useCobrandingOnMobile'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import { useSingleCTAPriceComparison } from '@/hooks/features/useSingleCTAPriceComparison'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import Markdown from '../../global/Markdown'
import DailyPrices from './DailyPrices'
import InsurerComparison from './InsurerComparison'
import Pill from './Pill'
import styles from './PriceComparison.module.scss'

interface Props {
  ctaClick: (props: { flow?: string; label?: string }) => void
  ctaLabel: string
  heading: string
  policiesApproved: number
}

const trustImages = [
  {
    alt: 'Business Insider',
    url: 'https://res.cloudinary.com/getethos/image/upload/v1742253551/00_CMS/business_insider_gray_ehoxtg.svg',
  },
  {
    alt: 'CNBC',
    url: 'https://res.cloudinary.com/getethos/image/upload/v1742253382/00_CMS/cnbc_gray_mseozl.svg',
  },
]

const PriceComparison = ({ ctaLabel, ctaClick, heading }: Props) => {
  const { headline } = useHeadlineRapidTest()
  const headingToShow = headline || heading

  const { isVariantA, isVariantB, isVariantC, isVariantD } =
    useSingleCTAPriceComparison()

  const {
    navBarControls: { hideNavBar },
  } = useModuleContext()
  const { isLaptopAndUp } = useDetectScreenSize()
  const { isTreatment1 } = useCobrandingOnMobile()
  const { isTreatment1: newPartnersTreatment } = useCobrandingNewPartners()

  let partnerLogoMobile: JSX.Element | null = null
  let backgroundComponent
  let pillText

  const { isForbesUtmTerm, isBestMoneyUtmTerm, isForbesUsaTodayTerm } =
    useCobrandedUtmTerms()
  const shouldShowLogoWithPartner = isTreatment1 || newPartnersTreatment

  // --------- TODO: clean up after new partners treatment is over ---------
  const isForbesHeaderEnabled = useForbesHeaderNavbar(shouldShowLogoWithPartner)
  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar(
    shouldShowLogoWithPartner
  )
  const { isEnabled: isBestMoneyHeaderEnabled } = useBestMoneyHeader(
    shouldShowLogoWithPartner
  )
  // ------------------------------------------------------------------------

  // --------- TODO: clean up after new partners treatment is over ---------
  if (isForbesUtmTerm || isForbesHeaderEnabled) {
    partnerLogoMobile = <ForbesLogo />
  } else if (isBestMoneyUtmTerm || isBestMoneyHeaderEnabled) {
    partnerLogoMobile = <BestMoneyLogo />
  } else if (isForbesUsaTodayTerm || isForbesUsaTodayHeaderEnabled) {
    partnerLogoMobile = <ForbesUsaTodayLogo />
  }
  // ------------------------------------------------------------------------

  // Override with latest partner logo treatment
  partnerLogoMobile = useCheckCobrandingNewPartnersLogos(partnerLogoMobile)

  useEffect(() => {
    hideNavBar(!isLaptopAndUp)
  }, [hideNavBar, isLaptopAndUp])

  if (isVariantA) backgroundComponent = <DailyPrices />
  if (isVariantB) backgroundComponent = <DailyPrices annual />
  if (isVariantC) {
    backgroundComponent = <InsurerComparison />
    pillText = 'Buy 100% Online'
  }
  if (isVariantD) backgroundComponent = <InsurerComparison />

  return (
    <section className={styles.wrapper}>
      <div
        className={classNames(styles.container, {
          [styles.variantA]: isVariantA,
          [styles.variantB]: isVariantB,
        })}
      >
        <div className={styles.mainContent}>
          <div
            className={
              shouldShowLogoWithPartner ? styles.logoWithPartner : styles.logo
            }
          >
            {shouldShowLogoWithPartner && partnerLogoMobile ? (
              <>
                <Image
                  src={consumerLogoBlack}
                  alt="Ethos Logo"
                  width={87}
                  height={16}
                  fetchPriority="high"
                />
                <Plus />
                <div className={styles.mobileLogo}>{partnerLogoMobile}</div>
              </>
            ) : (
              <img src={consumerLogo.src} alt="Ethos Logo" />
            )}
          </div>
          {isVariantC && <Pill>Buy 100% Online</Pill>}
          <h1 className={styles.header}>
            <Markdown input={headingToShow} />
          </h1>
          {isVariantC ? (
            <div className={styles.lines}>
              <p>No medical exams or blood tests</p>
              <hr />
              <p>Great rates from top insurers</p>
            </div>
          ) : (
            <div className="mb-[42px] flex items-center gap-2">
              <p className={styles.subheader}>no medical exams</p>
              <div className="mb-1 h-[6px] w-[6px] rounded-full bg-[#82B0AB]"></div>
              <p className={styles.subheader}>no blood tests</p>
            </div>
          )}
          <Button
            className={styles.button}
            buttonTitle={ctaLabel}
            alignment="center"
            variant="salamander"
            type="submit"
            size="lg"
            onClick={() => ctaClick({ label: ctaLabel })}
          />
          <div
            className={classNames(styles.ellipse, {
              [styles.nudgedUp]: isVariantC || isVariantD,
            })}
          ></div>
          {backgroundComponent}
          <div
            className={classNames(styles.trustImages, {
              [styles.solid]: isVariantA || isVariantB,
            })}
          >
            {trustImages.map(({ alt, url }, index) => (
              <CloudinaryImage
                publicId={url}
                alt={alt}
                className={styles.trustImage}
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[142, 142, 142, 142]}
                width={[85, 85, 85, 85]}
                key={`trustImage-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PriceComparison
