import { ReactNode } from 'react'

import classNames from 'classnames'

import styles from './ComparisonStack.module.scss'

interface Props {
  children: ReactNode
  featured?: boolean
}

const DailyPrice = ({ children, featured }: Props) => (
  <div
    className={classNames(styles.dailyPrice, {
      [styles.featured]: featured,
    })}
  >
    <span className={styles.price}>{children}</span>
    <span className={styles.perDay}>per day</span>
  </div>
)

export default DailyPrice
