import { WILLS_V3_5, WILLS_V3_6 } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const WILLS_V3_4_CONTROL_FLOW = 'wills-v3-4-control'
export const WILLS_V3_4_T1_FLOW = 'wills-v3-4-t1'
export const WILLS_V3_4_T2_FLOW = 'wills-v3-4-t2'
export const WILLS_V3_4_T3_FLOW = 'wills-v3-4-t3'
export const WILLS_V3_4_T4_FLOW = 'wills-v3-4-t4'

export const WILLS_V3_6_CONTROL_FLOW = 'wills-v3-6-control'
const WILLS_V3_6_T1_FLOW = 'wills-v3-6-t1'
const WILLS_V3_6_T2_FLOW = 'wills-v3-6-t2'
const WILLS_V3_6_T3_FLOW = 'wills-v3-6-t3'

export const useWillsFunnelFlow = () => {
  const { variation, isLoading } = useDecisionForFlag({
    name: WILLS_V3_6.EXPERIMENT_KEY,
    fallbackVariation: WILLS_V3_6.VARIATIONS.CONTROL,
  })

  let flow
  let isTreatment = false

  switch (variation) {
    case WILLS_V3_6.VARIATIONS.CONTROL:
      flow = WILLS_V3_6_CONTROL_FLOW
      isTreatment = true
      break

    case WILLS_V3_6.VARIATIONS.TREATMENT_1:
      flow = WILLS_V3_6_T1_FLOW
      isTreatment = true
      break

    case WILLS_V3_6.VARIATIONS.TREATMENT_2:
      flow = WILLS_V3_6_T2_FLOW
      isTreatment = true
      break
    case WILLS_V3_6.VARIATIONS.TREATMENT_3:
      flow = WILLS_V3_6_T3_FLOW
      isTreatment = true
      break

    default:
      flow = undefined
  }

  return {
    isLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
