import { memo, useEffect, useMemo, useState } from 'react'

// based off values set in frontend/packages/design-system/Media.scss#
export const PHONE_RANGE_END = 599
const TABLET_RANGE_START = 600
const TABLET_RANGE_END = 899
const LAPTOP_RANGE_START = 900
const LG_RANGE_START = 1024
const LAPTOP_RANGE_END = 1199
const DESKTOP_RANGE_START = 1200

export default function useDetectScreenSize() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth)
      const updateWindowDimensions = () => {
        const newWidth = window.innerWidth
        setWidth(newWidth)
      }

      window.addEventListener('resize', updateWindowDimensions)
      return () => window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  const dimensions = useMemo(
    () => ({
      isMobile: width <= PHONE_RANGE_END,
      isTablet: width >= TABLET_RANGE_START && width <= TABLET_RANGE_END,
      isTabletAndUp: width >= TABLET_RANGE_START,
      isTabletAndSmaller: width <= TABLET_RANGE_END,
      isLgAndUp: width >= LG_RANGE_START,
      isLaptop: width >= LAPTOP_RANGE_START && width <= LAPTOP_RANGE_END,
      isLaptopAndUp: width >= LAPTOP_RANGE_START,
      isLaptopAndSmaller: width <= LAPTOP_RANGE_END,
      isDesktop: width >= DESKTOP_RANGE_START,
    }),
    [width]
  )

  return dimensions
}
