import { useRouter } from 'next/router'

import Cookies from 'js-cookie'
import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'
import { CREDIT_KARMA_COBRANDED_HEADER } from 'lib/@getethos/experiments/feature_constants'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'

import { CobrandingNewPartners } from '../cobranded/useGetCobrandingNewPartners'

export const useCreditKarmaHeader = (
  newPartnersTreatment = false
): {
  isEnabled: boolean
  isLoading: boolean
} => {
  const { asPath } = useRouter()

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath =
    CREDIT_KARMA_COBRANDED_HEADER.audiences?.includes(pathnameWithoutQuery)

  const {
    variables: { isEnabled },
    isLoading,
  } = useFeatureVariable(
    CREDIT_KARMA_COBRANDED_HEADER.name,
    !isValidAudiencePath
  )

  if (isEnabled) {
    if (newPartnersTreatment) {
      Cookies.set(
        COBRANDING_NEW_PARTNERS.cookieName,
        CobrandingNewPartners.CreditKarma,
        { expires: 365 }
      )
    }
    Cookies.set(
      CREDIT_KARMA_COBRANDED_HEADER.cookieName,
      CREDIT_KARMA_COBRANDED_HEADER.variations.on,
      { expires: 365 }
    )
  }

  return { isEnabled, isLoading }
}
