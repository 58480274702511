import React from 'react'

import dynamic from 'next/dynamic'

import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'

import { Loader } from '../global/Loader/Loader'
import styles from './EstimateWidget.module.scss'
import { EstimateWidgetVariations } from './common/variations'

const FloatingWidget = dynamic(() =>
  import('./FloatingVariation/FloatingWidget').then((mod) => mod.FloatingWidget)
)
const HeroBoxedWidget = dynamic(() =>
  import('./HeroBoxedVariation/HeroBoxedWidget').then(
    (mod) => mod.HeroBoxedWidget
  )
)

export enum EstimateWidgetImageLayout {
  BackgroundImage = 'backgroundImage',
  MultiImage = 'multiImage',
  FullBleed = 'fullBleed',
  CoBranded = 'coBranded',
  ForegroundImage = 'foregroundImage',
  StraightToApp = 'straightToApp',
  External = 'external',
  FinalExpense = 'finalExpense',
}

export interface EstimateWidgetModuleData {
  type: string
  variation: EstimateWidgetVariations
  backgroundColor?:
    | 'buttercup'
    | 'duckegg'
    | 'forest'
    | 'gray'
    | 'moss'
    | 'sand'
    | 'white'
  heading?: string
  heading2?: string
  enableH1?: boolean
  headingSize?: string
  subHeading?: string
  subHeading2?: string
  displaySubHeading?: boolean
  displaySubHeading2?: boolean
  groupImage?: string
  hideMediaMobile?: boolean
  imageAlt?: string
  firstButton?: string
  continueLabel?: string
  saveLabel?: string
  disclaimerText?: string
  disclaimerColor?: 'white' | 'darkGrey'
  genderLabel?: string
  showDesiredCoverage?: boolean
  desiredCoverageLabel?: string
  birthLabel?: string
  nicotineLabel?: string
  healthLabel?: string
  zipLabel?: string
  displayEstimatedCredit?: boolean
  estimatedCreditLabel?: string
  estimatedCreditFootnote?: string
  coverageLabel?: string
  trustImageGroups?: Array<{
    groupImage: string
    imageAlt: string
  }>
  termLabel?: string
  emailHeading?: string
  emailSubHeading?: string
  emailSendLabel?: string
  useFinalExpensePriceRange?: boolean
  imageLayout?: EstimateWidgetImageLayout | null
  supportingImage?: string
  supportingImageAlt?: string
  estimateInfoCopy?: string
  estimateRangeCopy?: string
  dailyDollars?: boolean
  dailyPremium?: boolean
  priceElasticity?: boolean
  priceAnchoring?: boolean
  showSinglePrice?: boolean
  salamanderForm?: boolean
  cypressForm?: boolean
  subText?: string
  ratesCopy?: boolean
  displayTrust?: boolean
  disableSave?: boolean
  experimentKey?: string
  disablePresets?: boolean
  treatmentVariant?: string
  pressWreaths?: boolean
}

const EstimateWidget = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  if (moduleData?.dailyPremium) {
    moduleData.estimateRangeCopy = 'Starting at:'
  }
  const { isLoading: isLoadingHeadlineRapidTest } = useHeadlineRapidTest()

  const isLoading = isLoadingHeadlineRapidTest

  const modules = {
    [EstimateWidgetVariations.FLOATING_LEAN]: (
      <FloatingWidget moduleData={moduleData} />
    ),
    [EstimateWidgetVariations.BOXED]: (
      <HeroBoxedWidget moduleData={moduleData} />
    ),
  }

  const ModuleToRender = modules[moduleData.variation as keyof typeof modules]

  return (
    <div className={styles.wrapper}>
      <Loader isLoading={isLoading} />
      {ModuleToRender}
    </div>
  )
}

export default EstimateWidget
