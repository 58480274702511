import { useRouter } from 'next/router'

import Cookies from 'js-cookie'
import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'
import { useGetQueryParam } from 'src/hooks/useGetQueryParam'

import { CobrandingNewPartners } from './useGetCobrandingNewPartners'

const audiencePaths = ['/life/quick-online-quote/']

export const useForbesHeaderNavbar = (newPartnersTreatment?: boolean) => {
  const { asPath } = useRouter()
  const queryParams = useGetQueryParam('utm_term')

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath = audiencePaths.includes(pathnameWithoutQuery)

  const isForbesUtmTerm = queryParams === '1955282'

  const isEnabled = isValidAudiencePath && isForbesUtmTerm

  if (isEnabled) {
    if (newPartnersTreatment) {
      Cookies.set(
        COBRANDING_NEW_PARTNERS.cookieName,
        CobrandingNewPartners.Forbes,
        { expires: 365 }
      )
    }
    // cookie used by PreInterview
    Cookies.set('FORBES-HEADER-EXPERIMENT', 'on', {
      expires: 365,
    })
  }

  return isEnabled
}
