import React, { memo, useState } from 'react'

import styles from './BlockedIp.module.scss'

interface BlockedIpInterface {
  moduleData: any
}

enum TorqStatus {
  Idle = 'Idle',
  Success = 'Success!',
  UnblockFailure = 'Unblock Failure',
  ApiFailure = 'Something went wrong. Please wait 5 minutes and try again.',
  Pending = 'Sending request...',
}

const PROD_HOMEPAGE_LINK = 'https://ethos.com/'
const STAGING_HOMEPAGE_LINK = 'https://staging.ethos.com/'

const BlockedIp = () => {
  const [status, setStatus] = useState<TorqStatus>(TorqStatus.Idle)
  const [showFailurePage, setShowFailurePage] = useState(false)
  const [homepageLink, setHomepageLink] = useState(PROD_HOMEPAGE_LINK)

  const sendWebhook = async () => {
    const webhookUrl =
      'https://hooks.torq.io/v1/webhooks/6c0398d9-49e4-4fac-90b3-5aeab6ffdded/workflows/3f6ff969-d6bb-4eaa-8908-0ec6eba91ba6/sync'
    setStatus(TorqStatus.Pending)

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({}),
      })

      if (response.status !== 200) {
        setStatus(TorqStatus.ApiFailure)
        return
      }

      const data = await response.json()

      if (data.stage?.stage) {
        setHomepageLink(STAGING_HOMEPAGE_LINK)
        setStatus(TorqStatus.Success)
        window.location.href = STAGING_HOMEPAGE_LINK
      } else if (data.prod?.prod) {
        setHomepageLink(PROD_HOMEPAGE_LINK)
        setStatus(TorqStatus.Success)
        window.location.href = PROD_HOMEPAGE_LINK
      } else {
        setStatus(TorqStatus.UnblockFailure)
        setShowFailurePage(true)
      }
    } catch (error) {
      setStatus(TorqStatus.ApiFailure)
    }
  }

  const body = showFailurePage ? (
    <>
      <p>
        Thanks you!! We are unable to unblock you at the moment. Request to
        share screenshot of the error message to{' '}
        <a href="mailto:security@getethos.com">security@getethos.com</a>.
      </p>
    </>
  ) : (
    <>
      <div className={styles.imageContainer}>
        <img
          src="https://res.cloudinary.com/getethos/image/upload/v1679431334/Email%203/logo_prwmtu.png"
          alt="Ethos"
        />
      </div>
      <p>Your IP address was flagged as malicious and was blocked.</p>
      <p>
        If this is an error, please click &quot;Request Unblock&quot; below to
        request us to unblock your IP.
      </p>
      {status === TorqStatus.Idle ? (
        <button onClick={sendWebhook} className={styles.button}>
          Request Unblock
        </button>
      ) : (
        <div className={styles.status}>{status}</div>
      )}
      {status === TorqStatus.Success && (
        <a href={homepageLink}>Go to Ethos.com</a>
      )}
    </>
  )

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>{body}</div>
    </div>
  )
}

export default memo(BlockedIp)
