import React from 'react'

import Image from 'next/image'

import { useWillsModalContext } from '@/contexts/WillsModalContext'
import { Button } from '@getethos/ethos-design-system-v2'
import { CloudinaryImage } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import useNewWillsHeroSection from '@/hooks/features/useNewWillsHeroSection'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import WillsModal from '../Wills/WillsModal'
import { SingleCTAInterface } from '../interface'

const trustImagesV1 = [
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739978100/Trustpilot_Micro_szjphi.svg',
    alt: 'Trustpilot',
    mobileImageUrl: `https://res.cloudinary.com/getethos/image/upload/v1741075219/trustpilot_mobile_mscdpx.svg`,
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1741005118/bbb_logo.svg_ilbq5j.svg',
    alt: 'BBB',
  },
]

const trustImagesV2 = [
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739978100/Trustpilot2_nhzivh.svg',
    alt: 'Trustpilot',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739978100/number1_o0tney.svg',
    alt: 'EstatePlanning',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739978100/bbrating2_brrmxb.svg',
    alt: 'BBB rating',
  },
]

const WillsV3 = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  policiesApproved?: number
}) => {
  const { isTreatment1, isTreatment } = useNewWillsHeroSection()
  const { setIsModalOpen } = useWillsModalContext()
  const { ctaLabel } = moduleData

  const bullets = [
    `No expensive attorneys. No appointments.`,
    'Start and finish your will in minutes.',
    'Confirm who will care for your children.',
  ]

  const handleCTAClick = () => {
    if (moduleData.disableNavigateToApp) {
      setIsModalOpen(true)
    }
    ctaClick()
  }

  const trustWorthIcons = isTreatment1 ? (
    <TrustWorthIconsV1 trustImages={trustImagesV1} />
  ) : (
    <TrustWorthIconsV2 trustImages={trustImagesV2} />
  )

  return (
    <>
      <div className="m-auto flex w-full flex-col justify-between bg-[#E6F5EC] text-black md:max-w-[1440px] lg:flex-row lg:gap-[40px] lg:px-0 [&_p]:m-0">
        <div className="relative flex flex-col px-[16px] lg:pl-[80px]">
          <nav className="flex w-full py-[28px]">
            <div className="max-w-[86px max-h-[16px] lg:absolute lg:left-[64px] lg:top-[32px]">
              <CloudinaryImage
                className="h-[16px] w-[86px]"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1739433638/Ethos-Logo-Cypress_prnmdp.svg"
                alt="Ethos logo"
                crop={CloudinaryImage.CROP_METHODS.CROP}
                height={[16, 16, 16, 16]}
                width={[86, 86, 86, 86]}
              />
            </div>
          </nav>
          <div className="relative mt-4 flex h-full max-w-[567px] flex-col self-center lg:self-start">
            <div className="relative z-1 mx-auto my-auto">
              <h1 className="text-center font-['Cambon'] text-[32px] font-bold leading-[110%] tracking-[-1.44px] sm:text-left sm:text-[36px] sm:font-semibold xl:text-[48px] xl:tracking-[-1.92px]">
                Creating a will has never been easier
              </h1>
              <ul className="bullets mb-[48px] mt-6 flex list-none flex-col gap-y-[8px] p-0">
                {bullets.map((bullet) => (
                  <li
                    key={bullet}
                    className="flex items-start gap-x-[12px] gap-y-[8px] text-base tracking-[-0.28px] text-[#404040]"
                  >
                    <div className="h-6 w-6">
                      <CheckmarkIcon />
                    </div>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
              <Button
                className="flex !max-h-[60px] !min-h-[60px] w-full !rounded-[999px] !border-[#F49640] !bg-[#F49640] uppercase !text-black hover:brightness-105 lg:max-w-[260px]"
                onClick={handleCTAClick}
                buttonTitle={ctaLabel}
                size="lg"
                variant="salamander"
              />
            </div>
            {trustWorthIcons}
          </div>
        </div>

        <div className="w-fill hidden h-auto items-center p-[40px] lg:flex">
          <CloudinaryImage
            publicId="https://res.cloudinary.com/getethos/image/upload/v1740040035/AdobeStock_new_ypff8e.png"
            alt="Family"
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[652, 652, 652, 652]}
            width={[640, 640, 640, 640]}
          />
        </div>
        <div className="flex h-auto w-full items-start bg-cover bg-no-repeat lg:hidden">
          <Image
            src="https://res.cloudinary.com/getethos/image/upload/v1739436032/family_wuatza.png"
            className="!relative"
            alt="Family"
            fill
          />
        </div>
      </div>
      <WillsModal />
    </>
  )
}

const TrustWorthIconsV1 = ({
  trustImages,
}: {
  trustImages: { imageUrl: string; alt: string; mobileImageUrl?: string }[]
}) => {
  const { isLgAndUp } = useDetectScreenSize()

  return (
    <div className="mb-[40px] mt-[48px] flex max-h-[70px] max-w-[340px] items-center gap-x-[40px] self-center  lg:mt-auto lg:max-h-[90px] lg:max-w-[365px] lg:gap-x-[28px] lg:self-start">
      {trustImages.map((trustImage) => {
        const imageUrl = isLgAndUp
          ? trustImage.imageUrl
          : trustImage.mobileImageUrl || trustImage.imageUrl
        return (
          <div
            className={cn('flex items-center')}
            key={`trustImage-${imageUrl}`}
          >
            <div className="flex items-center">
              <CloudinaryImage
                className="h-full"
                publicId={imageUrl}
                alt={trustImage.alt}
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[85, 85, 85, 85]}
                width={[200, 200, 200, 200]}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const TrustWorthIconsV2 = ({
  trustImages,
}: {
  trustImages: { imageUrl: string; alt: string }[]
}) => {
  return (
    <div className="mb-[40px] mt-[48px] flex max-h-[112px] w-full max-w-[360px] items-center self-center lg:mb-[66px] lg:mt-auto lg:max-h-[90px] lg:max-w-[600px] lg:self-start">
      {trustImages.map((trustImage) => (
        <div
          className="flex max-h-[112px] max-w-[120px]  items-center lg:max-h-[112px] lg:max-w-[200px]"
          key={`trustImage-${trustImage.imageUrl}`}
        >
          <div className="flex items-center">
            <CloudinaryImage
              className="h-full"
              publicId={trustImage.imageUrl}
              alt={trustImage.alt}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[120, 120, 120, 120]}
              width={[200, 200, 200, 200]}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.79995 15.8998L4.59995 11.6998L3.19995 13.0998L8.79995 18.6998L20.8 6.6998L19.4 5.2998L8.79995 15.8998Z"
      fill="#044742"
    />
  </svg>
)

export default WillsV3
