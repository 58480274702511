const MassMutualLogo = () => (
  <svg
    width="67"
    height="9"
    viewBox="0 0 67 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_47611_13879)">
      <path
        d="M28.7129 5.32941C27.9938 5.18739 27.7008 5.10746 27.7008 4.90327C27.7008 4.71685 27.8607 4.52152 28.3223 4.52152C28.6952 4.52152 29.0059 4.68135 29.1657 4.95653C29.2012 5.0187 29.2811 5.03646 29.3432 5.00093L30.2577 4.49489C30.2932 4.47713 30.3198 4.44163 30.3287 4.40613C30.3376 4.3706 30.3287 4.3262 30.3109 4.29957C29.8937 3.67813 29.2456 3.37628 28.3312 3.37628H28.2868C27.6564 3.37628 27.0971 3.58047 26.7243 3.94447C26.4402 4.21967 26.2804 4.58369 26.2804 4.94767C26.2804 6.02188 27.1859 6.33263 28.0737 6.49242L28.1447 6.51018C28.6863 6.60784 29.1124 6.68774 29.1124 6.92743C29.1124 7.14052 28.9082 7.35357 28.4555 7.35357C28.0027 7.35357 27.6742 7.16715 27.4878 6.80313C27.47 6.76764 27.4434 6.741 27.399 6.73214C27.3635 6.72324 27.328 6.72324 27.2925 6.741L26.2804 7.30031C26.2449 7.31807 26.2271 7.34471 26.2183 7.3802C26.2094 7.41573 26.2094 7.45123 26.2271 7.48673C26.6355 8.20586 27.3369 8.56097 28.3933 8.56097H28.4377C29.2545 8.56097 29.7516 8.24136 30.018 7.97504C30.3287 7.67319 30.5063 7.26481 30.5063 6.88306C30.5151 5.85322 29.6451 5.5336 28.7129 5.32941Z"
        fill="#7E7E7E"
      />
      <path
        d="M33.3028 5.32941C32.5837 5.18739 32.2907 5.10746 32.2907 4.90327C32.2907 4.71685 32.4505 4.52152 32.9122 4.52152C33.285 4.52152 33.5957 4.68135 33.7555 4.95653C33.791 5.0187 33.871 5.03646 33.9331 5.00093L34.8474 4.49489C34.883 4.47713 34.9095 4.44163 34.9184 4.40613C34.9275 4.3706 34.9184 4.3262 34.9007 4.29957C34.4835 3.67813 33.8354 3.37628 32.921 3.37628H32.8766C32.2463 3.37628 31.687 3.58047 31.3141 3.94447C31.03 4.21967 30.8702 4.58369 30.8702 4.94767C30.8702 6.02188 31.7758 6.33263 32.6636 6.49242L32.7346 6.51018C33.2761 6.60784 33.7023 6.68774 33.7023 6.92743C33.7023 7.14052 33.4981 7.35357 33.0453 7.35357C32.5925 7.35357 32.2641 7.16715 32.0776 6.80313C32.0599 6.76764 32.0332 6.741 31.9888 6.73214C31.9533 6.72324 31.9178 6.72324 31.8823 6.741L30.8702 7.30031C30.8347 7.31807 30.817 7.34471 30.8081 7.3802C30.7992 7.41573 30.7992 7.45123 30.817 7.48673C31.2253 8.20586 31.9267 8.56097 32.9831 8.56097H33.0275C33.8443 8.56097 34.3415 8.24136 34.6078 7.97504C34.9184 7.67319 35.096 7.26481 35.096 6.88306C35.1137 5.85322 34.2438 5.5336 33.3028 5.32941Z"
        fill="#7E7E7E"
      />
      <path
        d="M51.7687 6.244V4.78801C51.7687 4.73475 51.8132 4.69035 51.8662 4.69035H52.7008C52.7718 4.69035 52.8252 4.63709 52.8252 4.56606V3.62502C52.8252 3.55399 52.7718 3.50073 52.7008 3.50073H51.8662C51.8132 3.50073 51.7687 3.45634 51.7687 3.39419V1.89383C51.7687 1.79617 51.6621 1.73403 51.5822 1.77842L50.4191 2.41762C50.375 2.44426 50.357 2.47977 50.357 2.53304V3.39419C50.357 3.44746 50.3128 3.50073 50.2506 3.50073H49.7357C49.6647 3.50073 49.6113 3.55399 49.6113 3.62502V4.56606C49.6113 4.63709 49.6647 4.69035 49.7357 4.69035H50.2506C50.304 4.69035 50.357 4.73475 50.357 4.79691V6.57248C50.357 7.62006 51.2092 8.45458 52.2568 8.46345C52.4345 8.46345 52.6033 8.48122 52.763 8.44572C52.8075 8.43682 52.8428 8.39245 52.8428 8.33919V7.17619C52.8428 7.13179 52.7987 7.09626 52.7542 7.11402C52.6832 7.13179 52.6122 7.14952 52.5412 7.14952C52.1859 7.14952 51.893 6.90983 51.8132 6.58135C51.7952 6.55472 51.7687 6.42156 51.7687 6.244Z"
        fill="#7E7E7E"
      />
      <path
        d="M54.8495 6.01299V3.60708C54.8495 3.53606 54.7962 3.48279 54.7252 3.48279H53.5533C53.4823 3.48279 53.429 3.53606 53.429 3.60708V6.14615C53.429 6.85638 53.6419 7.46008 54.0326 7.89509C54.4232 8.32123 54.9739 8.55205 55.6217 8.55205C56.1546 8.55205 56.6163 8.42776 57.0511 8.08154L57.0779 8.06378V8.32123C57.0779 8.39226 57.1309 8.44552 57.2023 8.44552H58.3739C58.4448 8.44552 58.4982 8.39226 58.4982 8.32123V3.60708C58.4982 3.53606 58.4448 3.48279 58.3739 3.48279H57.2023C57.1309 3.48279 57.0779 3.53606 57.0779 3.60708V5.98636C57.0779 6.78535 56.6604 7.25589 55.9593 7.25589C55.249 7.25589 54.8495 6.79425 54.8495 6.01299Z"
        fill="#7E7E7E"
      />
      <path
        d="M45.3675 6.01299V3.60708C45.3675 3.53606 45.3145 3.48279 45.2435 3.48279H44.0716C44.0006 3.48279 43.9473 3.53606 43.9473 3.60708V6.14615C43.9473 6.85638 44.1603 7.46008 44.5509 7.89509C44.9415 8.32123 45.4918 8.55205 46.14 8.55205C46.6726 8.55205 47.1343 8.42776 47.5694 8.08154L47.5959 8.06378V8.34786C47.5959 8.40113 47.6404 8.45439 47.7026 8.45439H48.9278C48.9808 8.45439 49.0253 8.41003 49.0253 8.34786V3.60708C49.0253 3.53606 48.972 3.48279 48.901 3.48279H47.7202C47.6492 3.48279 47.5959 3.53606 47.5959 3.60708V5.98636C47.5959 6.78535 47.1788 7.25589 46.4773 7.25589C45.7673 7.25589 45.3675 6.79425 45.3675 6.01299Z"
        fill="#7E7E7E"
      />
      <path
        d="M66.5505 0.748474H65.3521C65.2808 0.748474 65.2278 0.801741 65.2278 0.872764V8.34795C65.2278 8.40121 65.2719 8.45447 65.3341 8.45447H66.5594C66.6127 8.45447 66.666 8.41008 66.666 8.34795V0.846131C66.6569 0.792863 66.6039 0.748474 66.5505 0.748474Z"
        fill="#7E7E7E"
      />
      <path
        d="M22.9512 4.60145C23.6881 4.60145 24.2119 5.15188 24.2119 5.94201C24.2119 6.74103 23.6792 7.32697 22.9512 7.32697C22.2144 7.32697 21.6728 6.74103 21.6728 5.94201C21.6728 5.14302 22.1966 4.60145 22.9512 4.60145ZM20.2612 5.94201C20.2612 7.53116 21.3266 8.58763 22.9068 8.55214C23.3774 8.54324 23.8301 8.35681 24.203 8.06383V8.32128C24.203 8.39231 24.2562 8.44557 24.3273 8.44557H25.4992C25.5702 8.44557 25.6234 8.39231 25.6234 8.32128V3.60714C25.6234 3.53612 25.5702 3.48285 25.4992 3.48285H24.3273C24.2562 3.48285 24.203 3.53612 24.203 3.60714V3.8646L24.1764 3.84684C23.7769 3.53612 23.3241 3.38519 22.8181 3.38519C21.291 3.38519 20.2612 4.41502 20.2612 5.94201Z"
        fill="#7E7E7E"
      />
      <path
        d="M61.7652 4.60144C62.502 4.60144 63.0258 5.15185 63.0258 5.94201C63.0258 6.741 62.4932 7.32693 61.7652 7.32693C61.0284 7.32693 60.4866 6.741 60.4866 5.94201C60.4866 5.14298 61.0104 4.60144 61.7652 4.60144ZM59.0752 5.94201C59.0752 7.53112 60.1405 8.5876 61.7207 8.5521C62.1912 8.5432 62.644 8.35678 63.017 8.06379V8.34791C63.017 8.40117 63.0615 8.45444 63.1236 8.45444H64.3486C64.4019 8.45444 64.4464 8.41004 64.4464 8.34791V3.58935C64.4464 3.53608 64.4019 3.49169 64.3486 3.49169H63.1236C63.0703 3.49169 63.017 3.53608 63.017 3.58935V3.87344L62.9905 3.85569C62.591 3.54496 62.1382 3.39404 61.6321 3.39404C60.1051 3.38516 59.0752 4.41499 59.0752 5.94201Z"
        fill="#7E7E7E"
      />
      <path
        d="M18.5211 0.810619L15.9376 4.62811L13.3541 0.810619C13.3275 0.76623 13.2831 0.748474 13.2299 0.748474H12.3421C12.2622 0.748474 12.1912 0.810619 12.1912 0.899398V8.30355C12.1912 8.38344 12.2533 8.45447 12.3421 8.45447H13.4784C13.5584 8.45447 13.6294 8.39234 13.6294 8.30355V3.77583L15.8222 7.01625C15.8843 7.10505 16.0086 7.10505 16.0708 7.01625L18.2636 3.77583V8.30355C18.2636 8.38344 18.3258 8.45447 18.4145 8.45447H19.5509C19.6308 8.45447 19.7018 8.39234 19.7018 8.30355V0.899398C19.7018 0.819497 19.6397 0.748474 19.5509 0.748474H18.6631C18.5921 0.748474 18.5477 0.775108 18.5211 0.810619Z"
        fill="#7E7E7E"
      />
      <path
        d="M42.0029 0.810619L39.4196 4.62811L36.8362 0.810619C36.8094 0.76623 36.7652 0.748474 36.7119 0.748474H35.8239C35.7441 0.748474 35.6731 0.810619 35.6731 0.899398V8.30355C35.6731 8.38344 35.7353 8.45447 35.8239 8.45447H36.9605C37.0404 8.45447 37.1114 8.39234 37.1114 8.30355V3.77583L39.3041 7.01625C39.3662 7.10505 39.4906 7.10505 39.5527 7.01625L41.7454 3.77583V8.30355C41.7454 8.38344 41.8076 8.45447 41.8966 8.45447H43.0152C43.095 8.45447 43.166 8.39234 43.166 8.30355V0.899398C43.166 0.819497 43.1039 0.748474 43.0152 0.748474H42.1272C42.0739 0.748474 42.0298 0.775108 42.0029 0.810619Z"
        fill="#7E7E7E"
      />
      <path
        d="M10.3534 7.39799C10.3534 6.75879 9.82955 6.23499 9.19035 6.23499C8.55115 6.23499 8.02734 6.75879 8.02734 7.39799C8.02734 8.03722 8.55115 8.56099 9.19035 8.56099C9.82955 8.56989 10.3534 8.03722 10.3534 7.39799Z"
        fill="#7E7E7E"
      />
      <path
        d="M6.70467 7.39799C6.70467 6.75879 6.18087 6.23499 5.54167 6.23499C4.90247 6.23499 4.37866 6.75879 4.37866 7.39799C4.37866 8.03722 4.90247 8.56099 5.54167 8.56099C6.18087 8.56989 6.70467 8.03722 6.70467 7.39799Z"
        fill="#7E7E7E"
      />
      <path
        d="M3.06477 7.39799C3.06477 6.75879 2.54098 6.23499 1.90177 6.23499C1.26256 6.23499 0.73877 6.75879 0.73877 7.39799C0.73877 8.03722 1.26256 8.56099 1.90177 8.56099C2.54098 8.56989 3.06477 8.03722 3.06477 7.39799Z"
        fill="#7E7E7E"
      />
      <path
        d="M8.53353 4.53929C8.53353 3.90008 8.00972 3.37628 7.37052 3.37628C6.73132 3.37628 6.20752 3.90008 6.20752 4.53929C6.20752 5.17849 6.73132 5.70229 7.37052 5.70229C8.00972 5.70229 8.53353 5.18739 8.53353 4.53929Z"
        fill="#7E7E7E"
      />
      <path
        d="M4.89363 4.53929C4.89363 3.90008 4.36982 3.37628 3.73063 3.37628C3.09142 3.37628 2.56763 3.90008 2.56763 4.53929C2.56763 5.17849 3.09142 5.70229 3.73063 5.70229C4.36982 5.70229 4.89363 5.18739 4.89363 4.53929Z"
        fill="#7E7E7E"
      />
    </g>
    <defs>
      <clipPath id="clip0_47611_13879">
        <rect
          width="66.24"
          height="8.15261"
          fill="white"
          transform="translate(0.699463 0.723755)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default MassMutualLogo
