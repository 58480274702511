import { COBRANDING_NEW_PARTNERS } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const useCobrandingNewPartners = () => {
  const {
    VARIATIONS: { CONTROL, TREATMENT_1 },
    EXPERIMENT_KEY,
  } = COBRANDING_NEW_PARTNERS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  return {
    isLoading,
    isTreatment1: isEnabled && variation === TREATMENT_1,
  }
}

export default useCobrandingNewPartners
