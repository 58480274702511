import { BRAND_COLORS_LANDING_PAGE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useBrandColorsLandingPageExperiment = () => {
  const { CONTROL, VARIANT_1, VARIANT_2, VARIANT_3 } =
    BRAND_COLORS_LANDING_PAGE.VARIATIONS
  const { isLoading, variation } = useDecisionForFlag({
    name: BRAND_COLORS_LANDING_PAGE.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  return {
    isControl: variation === CONTROL,
    isVariant1: variation === VARIANT_1,
    isVariant2: variation === VARIANT_2,
    isVariant3: variation === VARIANT_3,
    isLoading,
  }
}
