import EthosLogo from '../icons/EthosLogo'
import MassMutualLogo from '../icons/MassMutualLogo'
import NewYorkLifeLogo from '../icons/NewYorkLifeLogo'
import AnnualAdjustment from './AnnualAdjustment'
import styles from './ComparisonStack.module.scss'
import DailyPrice from './DailyPrice'

interface Props {
  annual?: boolean
}

const ComparisonStack = ({ annual = false }: Props) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <EthosLogo />
      <DailyPrice featured>2.17</DailyPrice>
    </div>
    <div className={styles.card}>
      <NewYorkLifeLogo />
      {annual ? (
        <AnnualAdjustment amount="124.10" />
      ) : (
        <DailyPrice>2.51</DailyPrice>
      )}
    </div>
    <div className={styles.card}>
      <MassMutualLogo />
      {annual ? (
        <AnnualAdjustment amount="192.45" />
      ) : (
        <DailyPrice>2.70</DailyPrice>
      )}
    </div>
  </div>
)

export default ComparisonStack
