import styles from './ComparisonStack.module.scss'

interface Props {
  amount: string
}

const AnnualAdjustment = ({ amount }: Props) => {
  const [dollars, cents] = amount.split('.')

  return (
    <div className={styles.annualAdjustment}>
      <span className={styles.adjustment}>
        {dollars}
        <span className={styles.cents}>{cents}</span>
      </span>
      <span className={styles.perYear}>more per year</span>
    </div>
  )
}

export default AnnualAdjustment
