import { COBRANDING_ON_MOBILE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useCobrandingOnMobile = () => {
  const {
    EXPERIMENT_KEY,
    VARIATIONS: { CONTROL, TREATMENT_1 },
  } = COBRANDING_ON_MOBILE
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  return {
    isLoading,
    isTreatment1: isEnabled && variation === TREATMENT_1,
  }
}
