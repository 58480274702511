import { CloudinaryImage } from 'ethos-design-system'

import ComparisonStack from './ComparisonStack'
import styles from './PriceComparison.module.scss'

interface Props {
  annual?: boolean
}

const DailyPrices = ({ annual = false }: Props) => {
  return (
    <div className={styles.dailyPrices}>
      <CloudinaryImage
        alt="Phone screen"
        className={styles.backgroundImage}
        height={[650, 650, 675, 675]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1740767188/Light_phone_spgmoi.svg"
        width={[301, 302, 329, 329]}
      />
      <p className={styles.leadingText}>Get a great rate with Ethos</p>
      <p className={styles.subHeading}>
        We’ll compare your rate
        <br />
        to 30+ top insurers.
      </p>
      <ComparisonStack annual={annual} />
      <p className={styles.trailingText}>
        Above rates are illustrative of a $1.5M, 20-year term policy for a
        32-year-old, non-smoker, male. Actual carriers, rates, and comparisons
        may vary per person and per product.
      </p>
    </div>
  )
}

export default DailyPrices
