import { ReactNode } from 'react'

import styles from './Pill.module.scss'

interface Props {
  children: ReactNode
  className?: string
}

const Eyebrow = ({ children, className }: Props) => {
  return <div className={[styles.pill, className].join(' ')}>{children}</div>
}

export default Eyebrow
