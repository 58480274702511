import {
  BabyCenterLogo,
  BusinessInsiderLogo,
  ChimeLogo,
  FundComLogo,
  GoodRxLogo,
  InsuranceRankedLogo,
  JoyWalletLogo,
  MarketWatchLogo,
  MoneyTalksNewsLogo,
  MoneyWiseLogo,
  MoonshotLogo,
  NeighborhoodAlertsLogo,
  PerkSpotLogo,
  SpotowerLogo,
  VoicedMediaLogo,
  WhatToExpectLogo,
} from '@/icons'

import useGetCobrandingNewPartner, {
  CobrandingNewPartners,
} from './useGetCobrandingNewPartners'

const useCheckCobrandingNewPartnersLogos = (
  currentPartnerLogo: JSX.Element | null
) => {
  const cobrandedPartner = useGetCobrandingNewPartner()

  let partnerLogo = currentPartnerLogo

  switch (cobrandedPartner) {
    // --------- TODO: clean up after new partners treatment is over and append existing ones ---------
    // case CobrandingNewPartners.Sofi:
    //   partnerLogo = <SoFiLogo />
    //   break
    // case CobrandingNewPartners.CreditKarma:
    //   partnerLogo = <CreditKarmaLogo />
    //   break
    // case CobrandingNewPartners.BestMoney:
    //   partnerLogo = <BestMoneyLogo />
    //   break
    // case CobrandingNewPartners.ForbesUsaToday:
    //   partnerLogo = <ForbesUsaTodayLogo />
    //   break
    // case CobrandingNewPartners.Forbes:
    //   partnerLogo = <ForbesLogo />
    //   break
    // ------------------------------------------------------------------------
    case CobrandingNewPartners.BabyCenter:
      partnerLogo = <BabyCenterLogo />
      break
    case CobrandingNewPartners.BusinessInsider:
      partnerLogo = <BusinessInsiderLogo />
      break
    case CobrandingNewPartners.Chime:
      partnerLogo = <ChimeLogo />
      break
    case CobrandingNewPartners.FundCom:
      partnerLogo = <FundComLogo />
      break
    case CobrandingNewPartners.InsuranceRanked:
      partnerLogo = <InsuranceRankedLogo />
      break
    case CobrandingNewPartners.JoyWallet:
      partnerLogo = <JoyWalletLogo />
      break
    case CobrandingNewPartners.MoneyTalksNews:
      partnerLogo = <MoneyTalksNewsLogo />
      break
    case CobrandingNewPartners.MoneyWise:
      partnerLogo = <MoneyWiseLogo />
      break
    case CobrandingNewPartners.Moonshot:
      partnerLogo = <MoonshotLogo />
      break
    case CobrandingNewPartners.PerkSpot:
      partnerLogo = <PerkSpotLogo />
      break
    case CobrandingNewPartners.Spotower:
      partnerLogo = <SpotowerLogo />
      break
    case CobrandingNewPartners.VoicedMedia:
      partnerLogo = <VoicedMediaLogo />
      break
    case CobrandingNewPartners.WhatToExpect:
      partnerLogo = <WhatToExpectLogo />
      break
    case CobrandingNewPartners.GoodRX:
      partnerLogo = <GoodRxLogo />
      break
    case CobrandingNewPartners.MarketWatch:
      partnerLogo = <MarketWatchLogo />
      break
    case CobrandingNewPartners.NeighborhoodAlerts:
      partnerLogo = <NeighborhoodAlertsLogo />
      break
    default:
      partnerLogo = currentPartnerLogo
      break
  }

  return partnerLogo
}

export default useCheckCobrandingNewPartnersLogos
