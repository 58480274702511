import { BRAND_COLORS } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useBrandColorsExperiment = () => {
  const { CONTROL, VARIANT_1, VARIANT_2, VARIANT_3 } = BRAND_COLORS.VARIATIONS
  const { isLoading, variation } = useDecisionForFlag({
    name: BRAND_COLORS.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  return {
    isControl: variation === CONTROL,
    isLoading,
    isVariant1: variation === VARIANT_1,
    isVariant2: variation === VARIANT_2,
    isVariant3: variation === VARIANT_3,
  }
}
