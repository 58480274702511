import React, { PropsWithChildren, useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { LEGAL_BANNER_COOKIE, LEGAL_BANNER_COOKIE_VALUE } from '@/constants'
import { FootnoteFooterProvider, UrlContext } from '@/contexts'
import { LegalBannerProvider } from '@/contexts/LegalBannerContext'
import { useModuleContext } from '@/contexts/ModuleContext'
import WillsModalProvider from '@/contexts/WillsModalContext'
import { Layout } from 'ethos-design-system'
import Cookies from 'js-cookie'
import useTrackMaxScroll from 'lib/@getethos/analytics/useTrackMaxScroll'

import { Navbar } from '@/components/Navigation/Navbar'
import { CmsFooterScripts } from '@/components/global/Footer/CmsFooterScripts'

import useCheckCobrandingNewPartnersLogos from '@/hooks/cobranded/useCheckCobrandingNewPartnersLogos'
import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useIsSofi } from '@/hooks/cobranded/useIsSofi'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import useCobrandingNewPartners from '@/hooks/features/useCobrandingNewPartners'
import { useCreditKarmaHeader } from '@/hooks/features/useCreditKarmaHeader'

import {
  BestMoneyLogo,
  CreditKarmaLogo,
  ForbesLogo,
  ForbesLogoMobile,
  ForbesUsaTodayLogo,
  SoFiLogo,
} from '../icons'
import { MainWrapper } from './MainWrapper'

const LegalBanner = dynamic(
  () => import('@/components/global/LegalBanner/LegalBanner')
)

const Footer = dynamic(() => import('@/components/Footer'))

interface CmsLayoutProps {
  pageContent: any
  settings: Record<string, any>
  footnoteFooterInfo: any
  url: string
  noNavbar?: boolean
  navbarLogoOnly?: boolean
  noFooter?: boolean
  noCookieDisclaimer?: boolean
  agentLayout?: boolean
  shouldLoadAgentsIFrameScript?: boolean
  noLegalBanner?: boolean
  isAstaLayout?: boolean
}

export const CMSLayout: React.FC<PropsWithChildren<CmsLayoutProps>> = ({
  pageContent,
  settings,
  noLegalBanner,
  noNavbar,
  navbarLogoOnly,
  noFooter,
  footnoteFooterInfo,
  url,
  agentLayout,
  shouldLoadAgentsIFrameScript = false,
  children,
  isAstaLayout = false,
}) => {
  useTrackMaxScroll()
  const { isTreatment1 } = useCobrandingNewPartners()
  const [showLegalBanner, setShowLegalBanner] = useState(false)

  const hasSingleCTA = pageContent.pageLayout?.some(
    (module: { type: string }) => module.type === 'SingleCTA'
  )

  const {
    footerControls: { isFooterHidden },
    navBarControls: { isNavBarHidden },
  } = useModuleContext()

  let partnerLogo: JSX.Element | null = null
  let partnerLogoMobile: JSX.Element | null = null

  useEffect(() => {
    if (
      Cookies.get(LEGAL_BANNER_COOKIE) !== LEGAL_BANNER_COOKIE_VALUE &&
      !noLegalBanner
    ) {
      setShowLegalBanner(true)
    }
  }, [noLegalBanner])

  // --------- TODO: clean up after new partners treatment is over ---------
  // Start header feature flags
  const isForbesHeaderEnabled = useForbesHeaderNavbar(isTreatment1)
  const { isEnabled: isBestMoneyHeaderEnabled } =
    useBestMoneyHeader(isTreatment1)
  const { isEnabled: isCreditKarmaHeaderEnabled } =
    useCreditKarmaHeader(isTreatment1)

  // TODO admin yaml setting for partner logo
  const isSofi = useIsSofi(isTreatment1)
  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar(isTreatment1)

  if (isForbesHeaderEnabled) {
    partnerLogo = <ForbesLogo />
    partnerLogoMobile = <ForbesLogoMobile />
  } else if (isSofi) {
    partnerLogo = <SoFiLogo />
    partnerLogoMobile = <SoFiLogo />
  } else if (isBestMoneyHeaderEnabled) {
    partnerLogo = <BestMoneyLogo />
    partnerLogoMobile = <BestMoneyLogo />
  } else if (isCreditKarmaHeaderEnabled) {
    partnerLogo = <CreditKarmaLogo />
    partnerLogoMobile = <CreditKarmaLogo />
  } else if (isForbesUsaTodayHeaderEnabled) {
    partnerLogo = <ForbesUsaTodayLogo />
    partnerLogoMobile = <ForbesUsaTodayLogo />
  }

  // End SoFi Cobranded header experiments
  // ------------------------------------------------------------------------

  // Check under flag and overrides values for new partners
  // Fallbacks to existing partner logo value if it is not in the treatment group
  partnerLogo = partnerLogoMobile =
    useCheckCobrandingNewPartnersLogos(partnerLogo)

  const shouldHideAccessibilityWidget = url.includes('external-estimate-widget')

  return (
    <>
      <WillsModalProvider>
        <LegalBannerProvider>
          <FootnoteFooterProvider footnoteFooterInfo={footnoteFooterInfo}>
            <UrlContext.Provider value={url}>
              <Layout>
                <Navbar
                  agentLayout={agentLayout || pageContent.agentLayout}
                  noNavbar={noNavbar || pageContent.noNavbar || isNavBarHidden}
                  navbarLogoOnly={navbarLogoOnly || pageContent.navbarLogoOnly}
                  animatedNavbar={pageContent.animatedNavbar} // todo rename globally in separate pr to navbarOptions
                  navbarCtaOverride={pageContent.navbarCtaOverride}
                  hideDesktopCta={pageContent.hideDesktopCta}
                  partnerLogo={partnerLogo}
                  partnerLogoMobile={partnerLogoMobile}
                  partnerLogoSeparator={isSofi ? 'pipe' : 'plus'}
                  settings={settings}
                  isAstaLayout={isAstaLayout}
                  isSofi={isSofi}
                />
                <MainWrapper>{children}</MainWrapper>
                <Footer
                  noFooter={
                    noFooter ||
                    pageContent.noFooter ||
                    (isAstaLayout && !hasSingleCTA) ||
                    isFooterHidden
                  }
                  partnerFooter={pageContent.partnerFooter}
                  blankFooter={pageContent.blankFooter}
                  agentLayout={!!agentLayout}
                  settings={settings}
                  footnoteFooter={pageContent.footnoteFooter}
                />
              </Layout>
            </UrlContext.Provider>
          </FootnoteFooterProvider>
          {showLegalBanner && (
            <LegalBanner
              settings={settings}
              isGFI={pageContent.isGFILegalBannerColor}
            />
          )}
        </LegalBannerProvider>
      </WillsModalProvider>
      <CmsFooterScripts
        shouldLoadAgentsIFrameScript={shouldLoadAgentsIFrameScript}
        hideAccessibilityWidget={shouldHideAccessibilityWidget}
      />
    </>
  )
}
