import { useRouter } from 'next/router'

export const useHomePageConstants = () => {
  const { asPath } = useRouter()

  const pathWithoutQuery = asPath.split('?')[0]

  const isHomePage = pathWithoutQuery === '/'

  if (!isHomePage)
    return {
      policiesApproved: null,
      googleReview: {
        stars: null,
        reviews: null,
      },
      trustPilotReview: {
        stars: null,
        reviews: null,
      },
    }

  return {
    policiesApproved: '3,700',
    googleReview: {
      stars: '4.5/5',
      reviews: '1,061 reviews',
    },
    trustPilotReview: {
      stars: '4.8/5',
      reviews: '2,417 reviews',
    },
  }
}
