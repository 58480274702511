import { useMemo } from 'react'

import { useRouter } from 'next/router'

import { useGetQueryParam } from 'src/hooks/useGetQueryParam'

import { useCobrandingOnMobile } from '../features/useCobrandingOnMobile'

const audiencePaths = ['/life/quick-online-quote/']

const FORBES_UTM_TERM = '1955282'
const FORBES_USA_TODAY_UTM_TERM = '4012387'
const BEST_MONEY_UTM_TERM = ['34020', '391604']

export const useCobrandedUtmTerms = () => {
  const { asPath } = useRouter()

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath = audiencePaths.includes(pathnameWithoutQuery)

  const { isTreatment1 } = useCobrandingOnMobile()
  const queryParams = useGetQueryParam('utm_term')

  const isForbesUtmTerm = useMemo(
    () =>
      isTreatment1 && queryParams === FORBES_UTM_TERM && isValidAudiencePath,
    [isTreatment1, queryParams, isValidAudiencePath]
  )
  const isForbesUsaTodayTerm = useMemo(
    () =>
      isTreatment1 &&
      queryParams === FORBES_USA_TODAY_UTM_TERM &&
      isValidAudiencePath,
    [isTreatment1, queryParams, isValidAudiencePath]
  )
  const isBestMoneyUtmTerm = useMemo(
    () =>
      isTreatment1 &&
      BEST_MONEY_UTM_TERM.includes(queryParams as string) &&
      isValidAudiencePath,
    [isTreatment1, queryParams, isValidAudiencePath]
  )

  return {
    isForbesUtmTerm,
    isForbesUsaTodayTerm,
    isBestMoneyUtmTerm,
  }
}
