import { WILLS_NEW_HERO_SECTION } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const useNewWillsHeroSection = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } =
    WILLS_NEW_HERO_SECTION.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: WILLS_NEW_HERO_SECTION.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const isTreatment1 = isEnabled && variation === TREATMENT_1
  const isTreatment2 = isEnabled && variation === TREATMENT_2

  const isTreatment = isEnabled && (isTreatment1 || isTreatment2)

  return {
    isLoading,
    isTreatment1,
    isTreatment2,
    isTreatment,
  }
}

export default useNewWillsHeroSection
