import { FC, PropsWithChildren } from 'react'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { useModuleContext } from '@/contexts/ModuleContext'

export const MainWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isDisclaimerOpen, disclaimerHeight } = useLegalBannerContext()
  const {
    footerControls: { isFooterHidden },
  } = useModuleContext()

  const paddingBottom =
    !isDisclaimerOpen || !isFooterHidden ? 0 : disclaimerHeight

  return <main style={{ paddingBottom: `${paddingBottom}px` }}>{children}</main>
}
