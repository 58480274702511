import React from 'react'

import PartnerIcons from '../icons/PartnerIcons'
import styles from './PriceComparison.module.scss'

const InsurerComparison = () => (
  <div className={styles.insurerComparison}>
    <p className={styles.leadingText}>Get the best rate with Ethos</p>
    <p className={styles.subHeading}>
      We search our network of A+ rated
      <br />
      insurers to find you the best price—fast.
    </p>
    <PartnerIcons className={styles.partnerIcons} />
  </div>
)

export default InsurerComparison
